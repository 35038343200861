$imgdir: "/assets/static_images";
$mtm-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
$mtm_light_green_color: #05c758;
$mtm_dark_green_color: #047940;
$mtm_grey: #ebebeb;
$mtm_light_grey: #f1f2f2;
$mtm_white: #ffffff;
$mtm_dark_grey: #023119;
$mtm_headline_color: $mtm_dark_grey;
$mtm_body_color: #414042;
$mtm_blue: #015aaa;
