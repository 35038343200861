@use "variables";

@media screen and (max-width: 768px) {
  .ask-to-register {
    margin: 24px;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .layout-container .router-wrapper {
    margin-top: 0 !important;
  }

  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}

html,
body {
  background: variables.$mtm_white;
  font-family: Arial;
  margin: unset;
  height: 100%;
  color: variables.$mtm_body_color;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: filson-bold;
    color: variables.$mtm_headline_color;
  }
  p {
    line-height: 22px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.titlecase {
  text-transform: capitalize;
}

mat-icon {
  vertical-align: middle;
}

button {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: variables.$mtm_white;
  }
}
.mat-button {
  font-family: Arial !important;
}
.mat-flat-button {
  padding: 2px 4px 4px 4px !important;
}

mat-dialog-container {
  overflow: unset;
}

.mat-drawer-container {
  background-color: variables.$mtm_white !important;
}

.mat-menu-content {
  .mat-menu-item {
    font-family: Arial !important;
  }
}
// dialog colors
.email-dialog-container {
  max-width: unset !important;
  .mat-dialog-container {
    padding: 0;
  }
}

.recipe-dialog-container {
  max-width: unset !important;
  .mat-dialog-container {
    padding: 0;
  }
}
.faded-backdrop {
  background: rgba(255, 255, 255, 0.7);
}

.cc-side-drawer-anchor {
  display: none !important;
}

.slick-slider {
  width: 80%;
  margin: 0 auto;
}

.evidon-banner {
  background-color: rgb(9, 171, 96) !important;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  opacity: 0.9;
  left: 50%;
  transform: translateX(-50%);
  color: white !important;
}

.evidon-banner .evidon-banner-title {
  color: white !important;
}

.evidon-banner a {
  color: white !important;
}

.evidon-prefdiag-overlay .evidon-prefdiag-sidebarlink {
  color: black !important;
}

.evidon-prefdiag-background {
  position: fixed !important;
  height: 100vh;
  background: rgb(221, 221, 221) !important;
  opacity: 0.9 !important;
  z-index: 200000;
}

.ask-to-register {
  .mat-button-focus-overlay {
    opacity: 0 !important;
  }

  .mat-dialog-container {
    padding: 0;
  }
}
